@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&family=Poppins:wght@400;600;700&display=swap');

.App {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: -8px;
}